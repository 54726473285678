import { type Elements } from '@kontent-ai/delivery-sdk'
import {
  type PortableTextObject,
  transformToPortableText,
  nodeParse
} from '@kontent-ai/rich-text-resolver'
import assign from 'lodash/assign'

export const isEmpty = (value: string | undefined) => {
  if (!value) return true
  return value == '<p><br></p>'
}

export const multipleChoiceToBoolean = (element: Elements.MultipleChoiceElement) => {
  return element.value.length > 0
    ? element.value[0].codename == 'yes' ||
        element.value[0].codename == 'show' ||
        element.value[0].codename == 'enable' ||
        element.value[0].codename == 'expanded'
    : false
}

export const splitPortableText = (
  characterCount: number,
  content: string
): {
  portableText1: PortableTextObject[]
  portableText2: PortableTextObject[]
} => {
  let portableText: PortableTextObject[]
  try {
    portableText = transformToPortableText(nodeParse(content))
  } catch (e) {
    console.error('Error parsing value', content)
    console.error('Error parsing JSON', e)
    return {
      portableText1: [],
      portableText2: []
    }
  }

  let currentCount = 0
  let splitIndex = 0

  if (portableText.length == 1 && characterCount > 0) {
    const { splitHtml } = useHtmlSplitter()
    const split = splitHtml(content, characterCount)
    return {
      portableText1: transformToPortableText(nodeParse(split[0])),
      portableText2: transformToPortableText(nodeParse(split[1]))
    }
  }

  for (let i = 0; i < portableText.length; i++) {
    const item = portableText[i]
    if (item.children) {
      for (const child of item.children) {
        if (child.text) {
          currentCount += child.text.length
          if (currentCount >= characterCount) {
            splitIndex = i
            break
          }
        }
      }
    }
    if (currentCount >= characterCount) {
      break
    }
  }

  return {
    portableText1: portableText.slice(0, splitIndex + 1),
    portableText2: portableText.slice(splitIndex + 1)
  }
}

export const modifyPortableTextBlock = (
  portableText: PortableTextObject[],
  modifier: (text: string) => string
): PortableTextObject[] => {
  return portableText.map((item) => {
    if (item._type === 'block' && item.children) {
      return assign({}, item, {
        children: item.children.map((child) => {
          if (child.text) {
            child.text = modifier(child.text)
          }
          return child
        })
      })
    }
    return assign({}, item)
  })
}

export const modifyPortableTextBlockQuotes = (
  portableText: PortableTextObject[]
): PortableTextObject[] => {
  return modifyPortableTextBlock(portableText, (text: string) => text.replace(/"([^"]*)"/g, '“$1”'))
}
