import { Parser } from 'htmlparser2'
import map from 'lodash/map'
import entries from 'lodash/entries'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import slice from 'lodash/slice'
import forEachRight from 'lodash/forEachRight'
import forEach from 'lodash/forEach'

export const useHtmlSplitter = () => {
  const splitHtml = (html: string, limit: number) => {
    let left = ''
    let right = ''
    let count = 0
    let tags: string[] = []
    let split = false

    const stripHtmlTags = (html: string) => html.replace(/<[^>]*>?/gm, '')

    if (limit === 0) limit = findSplitIndex(stripHtmlTags(html))

    const parser = new Parser({
      onopentag(name, attributes) {
        const attrString = map(entries(attributes), ([key, value]) => `${key}="${value}"`).join(' ')
        const tagString = `<${name}${attrString ? ` ${attrString}` : ''}>`

        if (!split) {
          left += tagString
        } else {
          right += tagString
        }
        tags.push(name)
      },
      ontext(text) {
        if (isEmpty(trim(text))) return

        if (count + text.length <= limit) {
          left += text
          count += text.length
        } else if (!split) {
          const remaining = limit - count
          const text1 = slice(text, 0, remaining).join('')
          const text2 = slice(text, remaining).join('')

          left += text1

          // close all open tags for the left side
          forEachRight(tags, (tag) => {
            left += `</${tag}>`
          })

          // open tags on the right side
          forEach(tags, (tag) => {
            right += `<${tag}>`
          })

          right += text2
          count += text1.length
          split = true
        } else {
          right += text
        }
      },
      onclosetag(name) {
        if (!split) {
          left += `</${name}>`
        } else {
          right += `</${name}>`
        }
        tags.pop()
      }
    })

    parser.write(html)
    parser.end()
    return [left, right]
  }

  return { splitHtml }
}

const findSplitIndex = (input: string): number => {
  input = input.trim()
  const middle = Math.floor(input.length / 2)
  const lastSpaceBeforeMiddle = input.lastIndexOf(' ', middle)
  const splitIndex =
    lastSpaceBeforeMiddle !== -1 ? lastSpaceBeforeMiddle : input.indexOf(' ', middle)
  return splitIndex == -1 ? middle : splitIndex
}
